<template>
  <div :class="darkmode || 'grey lighten-5'" class="pa-4" flat>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card
          :color="darkmode ? '#1E1E1E' : 'white'"
          class="elevation-0 pa-5 d-flex flex-column"
        >
          <v-form ref="form" lazy-validation>
            <CropImage
              :isEdit="loadingSave"
              :value="bodyRequest.path"
              :croped-image="value => (bodyRequest.path = value)"
              :clearForm="clearFormImage"
              :aspectRatio="16 / 9"
              :initialAspectRatio="16 / 9"
              typeSize="landscape"
              @deleteImage="bodyRequest.path = ''"
              @getImageName="value => (imageName = value)"
            />
            <v-text-field
              v-model="bodyRequest.title"
              :label="$t('imageSlider.title')"
              :disabled="loadingSave"
              class="pt-3"
              clearable
              outlined
              dense
            />
            <v-textarea
              v-model="bodyRequest.description"
              :label="$t('imageSlider.slider_upload')"
              :disabled="loadingSave"
              outlined
              dense
            />
            <v-divider class="my-5" />
            <div class="d-flex justify-end">
              <v-btn
                v-if="isEdit"
                :disabled="loadingSave"
                outlined
                depressed
                color="primary"
                class="mr-2"
                @click="isEdit = false"
              >
                <v-icon>mdi-close</v-icon>
                <span v-if="$vuetify.breakpoint.smAndUp" class="ml-2">
                  {{ $t("app.cancel") }}
                </span>
              </v-btn>
              <v-btn
                :loading="loadingSave"
                class="gradient-primary"
                depressed
                dark
                @click="save"
              >
                <v-icon class="mr-2">mdi-content-save</v-icon>
                <span v-if="$vuetify.breakpoint.smAndUp">
                  {{ $t("app.save") }}
                </span>
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card
          :color="darkmode ? '#1E1E1E' : 'white'"
          class="elevation-0 pa-5"
        >
          <span class="font-weight-bold title">
            {{ $t("routes.image_slider") }}
          </span>
          <v-row>
            <v-col v-for="item in dataSlider" :key="item.id" cols="12" sm="6">
              <v-card class="mb-4">
                <v-img
                  :src="item.path"
                  gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                  height="150"
                >
                  <v-row>
                    <v-card-text
                      class="text-center white--text caption d-flex flex-column"
                    >
                      <span class="font-weight-bold">
                        {{ item.title }}
                      </span>
                      <span> {{ item.description }}</span>
                    </v-card-text>
                  </v-row>
                  <div style="position: absolute; right: 7px; bottom: 7px">
                    <v-btn
                      icon
                      class="white mr-2"
                      depressed
                      @click="handleEdit(item)"
                    >
                      <v-icon color="primary">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      class="white"
                      depressed
                      @click="toggleModalDelete(true, item.id)"
                    >
                      <v-icon color="error">mdi-delete-forever</v-icon>
                    </v-btn>
                  </div>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <ModalConfirm
      :isOpen="confirmDelete"
      :close="toggleModalDelete"
      :save="deleteSlider"
      :loading="loadingDelete"
    />
  </div>
</template>

<script>
import { create, edit, deleteImage } from "@/api/admin/settings/imageSlider";
import { list } from "@/api/admin/settings/mainInfo";
import i18n from "@/i18n";
export default {
  metaInfo: {
    title: i18n.t("routes.image_slider"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    CropImage: () => import("@/components/ImageCrop"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  },
  created() {
    this.getData();
  },
  watch: {
    isEdit(value) {
      if (!value) {
        this.reset();
      }
    }
  },
  data() {
    return {
      clearFormImage: false,
      imageName: null,
      loadingDelete: false,
      confirmDelete: false,
      isEdit: false,
      loadingSave: false,
      bodyRequest: {
        path: "",
        title: "",
        description: ""
      },
      dataSlider: [],
      deleteId: 0
    };
  },
  methods: {
    handleEdit(item) {
      this.reset();
      this.$nextTick(() => {
        this.isEdit = true;
        this.bodyRequest = JSON.parse(JSON.stringify(item));
      });
    },
    async deleteSlider() {
      this.loadingDelete = true;
      try {
        const response = await deleteImage({ id: this.deleteId });
        if (response.data.code) {
          this.getData();
          this.toggleModalDelete(false);
          this.snackBar(true, response.data.message);
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.loadingDelete = false;
    },
    toggleModalDelete(isOpen, id) {
      if (id) this.deleteId = id;
      this.confirmDelete = isOpen;
    },
    async getData() {
      try {
        const response = await list();
        if (response.data.code) {
          this.dataSlider = response.data.data.slider_photo;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    reset() {
      this.bodyRequest = {
        path: "",
        title: "",
        description: ""
      };
      this.bodyRequest.path = "";
      this.imageName = null;
      this.isEdit = false;
      this.clearFormImage = true;
      this.$refs.form.resetValidation();
    },
    async save() {
      this.loadingSave = true;
      if (this.bodyRequest.path) {
        if (this.$refs.form.validate()) {
          try {
            let response = {};
            if (this.isEdit) {
              let body = JSON.parse(JSON.stringify(this.bodyRequest));
              if (!this.imageName) body.path = ""; // set empty string if image not changed
              response = await edit(body);
            } else {
              response = await create(this.bodyRequest);
            }
            if (response.data.code) {
              this.snackBar(true, response.data.message);
              this.reset();
              this.getData();
            } else {
              this.snackBar(false, response.data.message);
            }
          } catch (error) {
            this.snackBar(false, error);
          }
        }
      } else {
        this.snackBar(false, this.$i18n.t("imageSlider.msg.slider_img"));
      }
      this.loadingSave = false;
    }
  }
};
</script>
