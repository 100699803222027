import request from "@/utils/request";

export function create(data) {
  return request({
    method: "post",
    url: "school-profile/create-slider",
    data: data
  });
}

export function edit(data) {
  return request({
    method: "post",
    url: "school-profile/edit-slider",
    data: data
  });
}

export function deleteImage(data) {
  return request({
    method: "post",
    url: "school-profile/delete-slider",
    data: data
  });
}
